import instance from './base.service';

class UserService {

  getProfile() {
    return instance.get(`api/users/profile.json`)
  }

  updateProfile(params) {
    return instance.put(`api/users/update_profile.json`, params)
  }

}

export default new UserService();
