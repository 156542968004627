<template>
  <div class="user-profile">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="profile-tabs">
          <router-link to="/user/orders">My orders</router-link>
          <router-link to="/user/profile">Profile</router-link>
          <a href="javascript:void(0)" @click="logout()">Logout</a>
        </div>

        <b-form autocomplete="off" @submit.stop.prevent="onFormSubmit">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Name / Chosen name">
                <b-form-input v-model="form.name" trim />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Last Name">
                <b-form-input v-model="form.last_name" trim />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Email">
                <b-form-input v-model="form.email" type="email" trim />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Confirm Email">
                <b-form-input
                  v-model="form.email_confirmation"
                  type="email"
                  trim
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b-form-group label="Password">
                <b-form-input v-model="form.password" type="password" trim />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b-form-group label="Confirm Password">
                <b-form-input
                  v-model="form.password_confirmation"
                  type="password"
                  trim
                />
              </b-form-group>
            </div>
          </div>
          <h4>Billing address</h4>
          <div class="row">
            <div class="col-8">
              <b-form-group label="Street address">
                <b-form-input v-model="form.address_street" trim />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Apt, ste, bldg.">
                <b-form-input v-model="form.address_complement" trim />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <b-form-group label="City">
                <b-form-input v-model="form.address_city" trim />
              </b-form-group>
            </div>
            <div class="col-3">
              <b-form-group label="Country">
                <!-- <b-form-input v-model="form.address_country" trim /> -->
                <b-form-select
                  v-model="form.address_country"
                  :options="countries"
                  class="form-control"
                  text-field="value"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group label="Zipcode">
                <b-form-input v-model="form.address_zipcode" trim />
              </b-form-group>
            </div>
            <div v-if="false" class="col-8">
              <b-form-group label="Mobile">
                <!-- <b-form-input v-model="form.mobile" trim /> -->
                <VuePhoneNumberInput
                  v-model="form.mobile"
                  name="mobile_country"
                  @update="onUpdateMobileCountry"
                  id="mobile_country"
                  :default-country-code="form.mobile_country"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between mt-4">
                <b-btn variant="link">Delete my account</b-btn>
                <b-overlay
                  :show="busy"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-btn variant="primary" type="submit"> Save Changes </b-btn>
                </b-overlay>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/mixin/toast";
import UserService from "@/services/user.service";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import countries from "./../json/countries.json";

export default {
  name: "UserProfile",
  metaInfo: {
    title: "My Profile",
  },
  mixins: [Toast],
  components: {
    VuePhoneNumberInput,
  },
  mounted() {
    this.getProfile();
  },
  data() {
    return {
      busy: false,
      form: {
        name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        address_street: "",
        address_complement: "",
        address_city: "",
        address_country: "",
        address_zipcode: "",
        mobile: "",
        accept_tos: false,
      },
      mobileCountry: null,
    };
  },
  computed: {
    countries: function () {
      return countries.sort();
    },
  },
  methods: {
    onUpdateMobileCountry(payload) {
      this.mobileCountry = payload;
    },
    getProfile() {
      UserService.getProfile().then(
        (result) => {
          if (result) {
            this.form = result.data;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    onFormSubmit() {
      this.busy = true;
      // this.mobileCountry.countryCode
      //   ? (this.form.mobile_country = this.mobileCountry.countryCode)
      //   : "";
      UserService.updateProfile({ user: this.form }).then(
        (result) => {
          this.busy = false;
          if (result.data.updated) {
            this.showToast("success", "Update with Success", " ");
          } else {
            this.showToast("danger", "Error", result.data.errors[0]);
          }
        },
        (error) => {
          this.busy = false;
          console.log(error);
        }
      );
    },
    logout() {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "",
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("AuthModule/logout", this.form);

            this.$router.push({
              name: "Home",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.user-profile {
  .page-title {
    margin: 6rem 0 3rem;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-size: 29px;
    font-weight: 700;
  }

  .login-cta {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .form-group {
    margin: 0.7rem 0;

    legend {
      text-transform: uppercase;
      color: var(--whole-form-legend-color);
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    .form-control {
      border-color: var(--whole-text);
      background: var(--whole-black-form-bg);
      font-size: 18px;
      color: var(--whole-text);
    }
  }

  h4 {
    font-weight: bold;
    font-size: 29px;
    line-height: 120%;
    margin-top: 2rem;
  }

  .custom-control.custom-checkbox {
    margin: 1rem 0 2rem;
  }

  .btn.btn-block.btn-primary {
    border-radius: 0;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
  }

  .address_country .flex-1 {
    display: none;
  }

  .country-selector__input,
  .address_country .select-country-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .country-selector__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  .input-tel__input {
    border-left: 1px solid var(--whole-text-opacity) !important;
  }

  .country-selector__input,
  .input-tel__input {
    border-color: var(--whole-text);
    background: var(--whole-black-form-bg);
    font-size: 18px;
    color: var(--whole-text);
  }
}
</style>
